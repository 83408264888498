import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  public actionUrl: string;
  static instance: GeneralService;

  constructor(private http: HttpClient) {
    GeneralService.instance = this;
  }

  public post(params) {
    return this.http
      .post(environment.apiUrl + this.actionUrl, params)
      .pipe(catchError(this.handleError));
  }

  public put<T>(param) {
    return this.http
      .put<T>(environment.apiUrl + this.actionUrl, param)
      .pipe(catchError(this.handleError));
  }

  public get<T>(params) {
    return this.http
      .get<T>(environment.apiUrl + this.actionUrl, {
        params: params
      })
      .pipe(catchError(this.handleError));
  }

  public delete<T>(params?) {
    return this.http
      .delete<T>(environment.apiUrl + this.actionUrl)
      .pipe(catchError(this.handleError));
  }

  public deleteWithBody<T>(params?) {
    return this.http
      .request<T>('delete', environment.apiUrl + this.actionUrl, {
        body: params
      })
      .pipe(catchError(this.handleError));
  }

  public getExternalApi<T>(endPoint, params) {
    return this.http
      .get<T>(endPoint, {
        params
      })
      .pipe(catchError(this.handleError));
  }

  public getSingle<T>() {
    return this.http
      .get<T>(environment.apiUrl + this.actionUrl)
      .pipe(catchError(this.handleError));
  }

  public getUrl<T>(url: string) {
    return this.http.get<T>(url).pipe(catchError(this.handleError));
  }

  public async getPromise<T>(params): Promise<any> {
    const data = await this.http
      .get(environment.apiUrl + this.actionUrl, {
        params: params
      })
      .toPromise();
    return data;
  }

  // Error handling
  private handleError(error: HttpErrorResponse) {
    swal.fire(error.statusText, error.error.error.message, 'error');

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(errorMessage);
  }
}
