import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { IUser } from 'src/app/shared/models/login.model';

let user: IUser = {
  company_id: null,
  is_company_admin: null,
  is_super_admin: false,
  is_operator: null,
  enabled: null,
  id: null,
  phone: null,
  last_name: null,
  name: null,
  password: null,
  photo: null,
  username: null
};

@Injectable()
export class UserGuard implements CanActivate {
  constructor(private location: Location, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    user = JSON.parse(
      localStorage.getItem('USER_DATA')
        ? localStorage.getItem('USER_DATA')
        : JSON.stringify({ rol: { id: 0, name: '' } })
    );
    if (route.data.validate_settings) {
      if (user.role.name !== 'customer_admin') {
        return true;
      } else {
        this.location.back();
        return false;
      }
    } else {
      if (route.data.validate_notifications) {
        if (user.role.name === 'super_admin' || user.role.name === 'operator') {
          return true;
        } else {
          this.location.back();
          return false;
        }
      } else {
        if (route.data.match_dashboard) {
          if (user.role.name === 'customer_admin') {
            this.router.navigate(['/main/dashboard/company']);
            return;
          }
          if (user.role.name === 'super_admin') {
            this.router.navigate(['/main/dashboard/admin']);
            return;
          }
          if (user.role.name === 'operator') {
            this.router.navigate(['/main/dashboard/operator']);
            return;
          }
          this.router.navigate(['/main/dashboard/user']);
          return;
        } else {
          if (route.data.rol == user.role.name) {
            return true;
          } else {
            const arrayCondition = [];
            Object.keys(route.data).forEach(key => {
              arrayCondition.push(user[key] === route.data[key]);
            });
            if (!arrayCondition.includes(false)) {
              return true;
            } else {
              this.location.back();
              return false;
            }
          }
        }
      }
    }
  }
}
