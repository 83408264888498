import * as tslib_1 from "tslib";
import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import swal from 'sweetalert2';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class GeneralService {
    constructor(http) {
        this.http = http;
        GeneralService.instance = this;
    }
    post(params) {
        return this.http
            .post(environment.apiUrl + this.actionUrl, params)
            .pipe(catchError(this.handleError));
    }
    put(param) {
        return this.http
            .put(environment.apiUrl + this.actionUrl, param)
            .pipe(catchError(this.handleError));
    }
    get(params) {
        return this.http
            .get(environment.apiUrl + this.actionUrl, {
            params: params
        })
            .pipe(catchError(this.handleError));
    }
    delete(params) {
        return this.http
            .delete(environment.apiUrl + this.actionUrl)
            .pipe(catchError(this.handleError));
    }
    deleteWithBody(params) {
        return this.http
            .request('delete', environment.apiUrl + this.actionUrl, {
            body: params
        })
            .pipe(catchError(this.handleError));
    }
    getExternalApi(endPoint, params) {
        return this.http
            .get(endPoint, {
            params
        })
            .pipe(catchError(this.handleError));
    }
    getSingle() {
        return this.http
            .get(environment.apiUrl + this.actionUrl)
            .pipe(catchError(this.handleError));
    }
    getUrl(url) {
        return this.http.get(url).pipe(catchError(this.handleError));
    }
    getPromise(params) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = yield this.http
                .get(environment.apiUrl + this.actionUrl, {
                params: params
            })
                .toPromise();
            return data;
        });
    }
    // Error handling
    handleError(error) {
        swal.fire(error.statusText, error.error.error.message, 'error');
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        }
        else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}
GeneralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GeneralService_Factory() { return new GeneralService(i0.ɵɵinject(i1.HttpClient)); }, token: GeneralService, providedIn: "root" });
