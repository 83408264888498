import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { SpinnerService } from '../../shared/components/spinner/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private spinnerService: SpinnerService) {}

  // Intercept the request to set header.
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinnerService.show();
    const token = localStorage.getItem('JWT_TOKEN');
    const params = {
      'Content-Language': 'en'
    };

    if (
      req.url.indexOf('login') === -1 &&
      req.url.indexOf('geocode') === -1 &&
      token &&
      req.url.indexOf('googleapis') === -1
    ) {
      req = req.clone({
        setHeaders: { Authorization: 'Token ' + token }
      });
    } else {
      req = req.clone({
        setHeaders: params
      });
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.spinnerService.hide();
          }
        },
        error => {
          this.spinnerService.hide();
        }
      )
    );
  }
}
