import { Component, AfterViewInit } from '@angular/core';
import * as LogRocket from 'logrocket';
import { GeneralService } from './core/http/general.service';
import { Router } from '@angular/router';
import { IUser } from './shared/models/login.model';
import { ICompany } from './shared/models/company.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'angular-template';

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly USER_DATA = 'USER_DATA';
  private readonly COMPANY_INFO = 'COMPANY_INFO';

  constructor(private service: GeneralService, private router: Router) {
    if (environment.production) {
      LogRocket.init(environment.apiIdLogRocket);
    }
  }

  ngAfterViewInit() {
    this.validateDataUser();
  }

  public identifyLogRocketUser(user: IUser): void {
    LogRocket.identify(user.id.toString(), {
      name: user.name + ' ' + user.last_name,
      email: user.username
    });
  }

  validateDataUser() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let resultParams = this.getParamsUrl('identity', urlParams);
    if (resultParams) {
      resultParams = atob(resultParams);
      this.service.actionUrl = 'user/verifyToken';
      this.service.post({ token: resultParams }).subscribe((data: IUser) => {
        this.setToken(resultParams);
        if (data.company_id) {
          localStorage.setItem(this.USER_DATA, JSON.stringify(data));
          if (environment.production) {
            this.identifyLogRocketUser(data);
          }
          this.service.actionUrl = 'company/' + data.company_id;
          this.service.get<ICompany>({}).subscribe((dataC: ICompany) => {
            localStorage.setItem(this.COMPANY_INFO, JSON.stringify(dataC));
            this.switchDashboard();
          });
        } else {
          localStorage.setItem(this.USER_DATA, JSON.stringify(data));
          this.switchDashboard();
        }
      });
    } else {
      const localDataUser: IUser = localStorage.getItem('USER_DATA')
        ? JSON.parse(localStorage.getItem('USER_DATA'))
        : null;
      if (
        !(
          localDataUser != null &&
          localDataUser.role != null &&
          localStorage.getItem('JWT_TOKEN') != null
        )
      ) {
        window.location.href = environment.domainLoginPortal;
      } else {
        if (window.location.pathname.length == 1) {
          this.switchDashboard();
        }
      }
    }
  }

  switchDashboard() {
    localStorage.removeItem('COMPANY_STORE');
    this.router.navigate(['/main']);
  }

  getParamsUrl(param, urlParams): string {
    return urlParams.get(param);
  }

  setToken(token: string): void {
    localStorage.setItem(this.JWT_TOKEN, token);
  }

  cleanParams() {
    const uri = window.location.toString();
    if (uri.indexOf('?') > 0) {
      const clean_uri = uri.substring(0, uri.indexOf('?'));
      window.history.replaceState({}, document.title, clean_uri);
    }
  }
}
