export const environment = {
  "apiIdLogRocket": "61w5ga/customer-portal-cdzvw",
  "apiUrl": "https://api-customer.loadgenie.com/",
  "domainLoginPortal": "https://loadgenie.com/",
  "firebase": {
    "apiKey": "AIzaSyDSYRkOt5w68Issbl4mUIx9JvVwi4J73rg",
    "appId": "1:651972395991:web:972f3edbc040591866c192",
    "authDomain": "rite-way-auto-transport.firebaseapp.com",
    "databaseURL": "https://rite-way-auto-transport.firebaseio.com",
    "messagingSenderId": "651972395991",
    "projectId": "rite-way-auto-transport",
    "storageBucket": "rite-way-auto-transport.appspot.com"
  },
  "production": "true",
  "resourcesUrl": "https://riteway-customerportal-static.s3.amazonaws.com/uploads/",
  "serverKey": "AAAAl8yVQ9c:APA91bGhoG4RSNaOzLD81ZFEukX_aOhei-pa9m85_HxQWF8rj9EY9xslOPInLHems59PsHmzXYiShQUGwzQ_46aY0xs52oyAeTxnhJQs0Nu26gAx_6ZL9KiuUv7LpKNhf21_BFXNii66",
  "socketUrl": "https://sockets.loadgenie.com",
  "tokenSocket": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTM0MSwiaWF0IjoxNTk0NjQ3NDI0LCJleHAiOjE1OTgyNDc0MjR9.tRXnxzM79PySapbBAnDuhOPxgXvY7mdjtE_7QDHkOOY"
}
