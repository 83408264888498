import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
const ɵ0 = () => import("./layouts/main-layout/main-layout.module.ngfactory").then(m => m.MainLayoutModuleNgFactory);
const routes = [
    {
        path: '',
        loadChildren: ɵ0,
    },
    { path: '404', component: PageNotFoundComponent },
    { path: '**', redirectTo: '/404' }
];
export const Routing = RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' });
export { ɵ0 };
