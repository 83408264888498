import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GeneralService } from 'src/app/core/http/general.service';
import { IAuth, IUser } from 'src/app/shared/models/login.model';
import { Router } from '@angular/router';
import { ICompany } from 'src/app/shared/models/company.model';
import * as LogRocket from 'logrocket';
import { UnsubscribeOnDestroyAdapter } from '../unsuscribe-adapter/unsuscribe-adapter';
@Injectable()
export class AuthenticationService extends UnsubscribeOnDestroyAdapter {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly USER_DATA = 'USER_DATA';
  private readonly COMPANY_INFO = 'COMPANY_INFO';
  private helper = new JwtHelperService();

  constructor(private service: GeneralService, private router: Router) {
    super();
  }

  public getToken(): string {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  public setToken(token: string): void {
    localStorage.setItem(this.JWT_TOKEN, token);
  }

  public isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.getToken();
    }
    if (!token) {
      return true;
    }
  }

  public login(user, callBack?): void {
    this.service.actionUrl = 'auth/login';
    this.subs.add(
      this.service.post(user).subscribe(
        (data: IAuth) => {
          this.setToken(data.token);
          this.identifyLogRocketUser(data.user);

          data.user.company_id
            ? this.getCompanyInfo(data.user.company_id, data.user)
            : this.switchDashboard(data.user);

          localStorage.setItem(this.USER_DATA, JSON.stringify(data.user));

          callBack(data);
        },
        error => {
          callBack(error);
        }
      )
    );
  }

  public logout(): void {
    setTimeout(() => {
      localStorage.removeItem(this.JWT_TOKEN);
      localStorage.removeItem(this.USER_DATA);
      localStorage.removeItem(this.COMPANY_INFO);
      localStorage.removeItem('data');
      localStorage.removeItem('quotes');
      localStorage.removeItem('COMPANY_STORE');
    }, 400);
  }

  private getCompanyInfo(id: number, user: IUser): void {
    this.service.actionUrl = 'company/' + id;
    this.subs.add(
      this.service.get<ICompany>({}).subscribe((dataC: ICompany) => {
        localStorage.setItem(this.COMPANY_INFO, JSON.stringify(dataC));
        this.switchDashboard(user);
      })
    );
  }

  private identifyLogRocketUser(user: IUser): void {
    LogRocket.identify(user.id.toString(), {
      name: user.name + ' ' + user.last_name,
      email: user.username
    });
  }

  private switchDashboard(user: IUser): void {
    if (user.role.name === 'customer_admin') {
      this.router.navigate(['/dashboard/company']);
      return;
    }
    if (user.role.name === 'super_admin') {
      this.router.navigate(['/dashboard/admin']);
      return;
    }
    if (user.role.name === 'operator') {
      this.router.navigate(['/dashboard/operator']);
      return;
    }
    this.router.navigate(['/dashboard/user']);
    return;
  }
}
