import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as socketIo from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { User } from 'firebase';
import { UserList, UserConnected } from '../shared/models/chat.model';

export const SERVER_URL_EVENTS = environment.socketUrl;

@Injectable()
export class SocketService {
  private socket;
  socketId = null;

  public initSocket(SERVER_URL: string, TOKEN: string): void {
    this.socket = socketIo(SERVER_URL, {
      transports: ['polling'],
      autoConnect: true,
      origins: '*',
      upgrade: false,
      secure: true,
      reconnection: true,
      reconnectionAttempts: 'Infinity',
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5,
      timeout: 20000,
      cookie: false,
      query: {
        Authorization: TOKEN
      },
      extraHeaders: {
        Authorization: TOKEN
      }
    });
  }

  public disconnect(): void {
    this.socket.emit('disconnect');
  }

  public onEvent(event: Event | string): Observable<any> {
    return new Observable<Event>(observer => {
      this.socket.on(event, (data?: any) => observer.next(data));
    });
  }

  public emitEvent(name: string, data: any) {
    this.socket.emit(name, data);
  }

  public offEvent(name: string): void {
    this.socket.off(name);
  }

  /***********************
   * CHAT SOCKET METHODS
   **********************/
  public send(message: Message): void {
    this.socket.emit('message', message);
  }

  public register(user: User): void {
    this.socket.emit('username', user);
  }

  public onUserList(): Observable<UserList> {
    return new Observable<UserList>(observer => {
      this.socket.on('userList', (data: UserList) => observer.next(data));
    });
  }

  public onExit(): Observable<UserConnected[]> {
    return new Observable<UserConnected[]>(observer => {
      this.socket.on('exit', (data: UserConnected[]) => observer.next(data));
    });
  }

  public onMessage(): Observable<Message> {
    return new Observable<Message>(observer => {
      this.socket.on('message', (data: Message) => observer.next(data));
    });
  }
}
