import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class SpinnerService {
    constructor() {
        this.visibility = new BehaviorSubject(false);
    }
    show() {
        this.visibility.next(true);
    }
    hide() {
        this.visibility.next(false);
    }
}
SpinnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(); }, token: SpinnerService, providedIn: "root" });
