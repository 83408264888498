import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
export class EventEmitterService {
    constructor() {
        this.invokeFirstComponentFunction = new EventEmitter();
    }
    onFirstComponentButtonClick() {
        this.invokeFirstComponentFunction.emit();
    }
}
EventEmitterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventEmitterService_Factory() { return new EventEmitterService(); }, token: EventEmitterService, providedIn: "root" });
