import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Routing } from './app-routing.module';
import { AuthGuard } from './core/auth-guard/auth.guard';
import { AuthenticationService } from './core/authentication/authentication.service';
import { AngularMaterialModule } from './shared/angular-material/angular-material.module';
import { Globals } from './shared/globals/globals';
import { EventEmitterService } from './services/event-emmiter.service';
import { UserGuard } from './core/user-guard/user.guard';
import { InterceptorService } from './core/http/interceptor.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SocketService } from './services/socket.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ToastrModule, ToastrService } from 'ngx-toastr';


@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    Routing,
    HttpClientModule,
    FlexLayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    InfiniteScrollModule,
    NgxMaterialTimepickerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
      positionClass: 'toast-top-center'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    AuthenticationService,
    AuthGuard,
    UserGuard,
    EventEmitterService,
    Globals,
    SocketService,
    ToastrService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
