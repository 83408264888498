import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate() {
    if (!this.authService.isTokenExpired()) {
      return true;
    }
    //this.router.navigate(['./login']);
    window.location.href = environment.domainLoginPortal;
    return false;
  }
}
