import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/components/spinner/spinner.service";
export class InterceptorService {
    constructor(spinnerService) {
        this.spinnerService = spinnerService;
    }
    // Intercept the request to set header.
    intercept(req, next) {
        this.spinnerService.show();
        const token = localStorage.getItem('JWT_TOKEN');
        const params = {
            'Content-Language': 'en'
        };
        if (req.url.indexOf('login') === -1 &&
            req.url.indexOf('geocode') === -1 &&
            token &&
            req.url.indexOf('googleapis') === -1) {
            req = req.clone({
                setHeaders: { Authorization: 'Token ' + token }
            });
        }
        else {
            req = req.clone({
                setHeaders: params
            });
        }
        return next.handle(req).pipe(tap((event) => {
            if (event instanceof HttpResponse) {
                this.spinnerService.hide();
            }
        }, error => {
            this.spinnerService.hide();
        }));
    }
}
InterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InterceptorService_Factory() { return new InterceptorService(i0.ɵɵinject(i1.SpinnerService)); }, token: InterceptorService, providedIn: "root" });
